@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

body {
  margin: 0px;
  background-color: #000000;
  font-family: "Inter", sans-serif;
}

.lato-font {
  font-family: "Lato", sans-serif;
}

.jump {
  animation: jump 1s infinite;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
